import store from '@/store'
import api from '@/api/users-api'
import { createEntityModule } from '@/store/bases/base-entity-module'
import app from '@/main'
import { notify } from '@kyvg/vue3-notification'

export default createEntityModule({
    namespaced: true,
    initialState: {
        id: null,
        username: '',
        firstname: '',
        lastname: '',
        email: '',
        password: '',
        repassword: '',
        roles: [],
        error: null,
    },
    getById: api.getById,
    create: api.create,
    update: api.update,
    remove: api.remove,
    invite: api.inviteUser,


    actions: {
        async save({ commit }, user) {
            try {
                const toSaveUser = Object.assign({}, user, {
                    id: undefined, // id cannot be updated or inserted
                    roles: undefined, // roles have to be set in another request
                })

                let savedUser = user

                if (user.id > 0) {
                    // update
                    await api.update(user.id, toSaveUser)
                } else {
                    // create
                    const response = await api.create(toSaveUser)
                    savedUser = response.data
                }

                // save admin roles
                if (store.state.auth.isAdmin
                    && user.roles !== undefined
                    && user.roles.length > 0)
                {
                    let roles = []
                    for (let role of user.roles) {
                        roles.push(role.id)
                    }

                    await api.associateRoles(parseInt(savedUser.id), roles)
                    savedUser.roles = user.roles
                }

                commit('set_item', savedUser)

                return savedUser
            } catch (ex) {
                app.$log.error(ex)
                const error = ex.response.data.error
                commit('set_error', error)

                return undefined
            }
        },
        async invite({ commit }, {email, rolesIds}) {
            try {  
                return await api.inviteUser(email, rolesIds);
            }
            catch (ex) {
                app.$log.error(ex)
                const error = 'unknown'
                if (ex.response.data.error.code == "invalid_email") {
                    error = 'invalid_email'
                }
                commit('set_error', error)

                return undefined
            }
            
        },
        async saveInvited({ commit }, createKey_user) {
                const toSaveUser = Object.assign({}, createKey_user.user, {
                    id: undefined, // id cannot be updated or inserted
                    repassword: undefined
                })
                const createKey = createKey_user.createKey

                let savedUser = toSaveUser

                await api.createInvited(createKey, toSaveUser)

                return savedUser
        },
        setUsername({ commit }, value) {
            commit('set_value', { key: 'username', value })
        },
        setFirstname({ commit }, value) {
            commit('set_value', { key: 'firstname', value })
        },
        setLastname({ commit }, value) {
            commit('set_value', { key: 'lastname', value })
        },
        setEmail({ commit }, value) {
            commit('set_value', { key: 'email', value })
        },
        setPassword({ commit }, value) {
            commit('set_value', { key: 'password', value })
        },
        setRoles({ commit }, value) {
            commit('set_value', { key: 'roles', value })
        },
    },

    getters: {
        getUsername: state => state.username,
        getFirstname: state => state.firstname,
        getLastname: state => state.lastname,
        getEmail: state => state.email,
        getPassword: state => state.password,
        getRoles: state => state.roles,
    }
})
