<template>
  <div class="page-not-found">
    <img class="logo" src="@/assets/imgs/thuasne-logo.png" alt="thuasne logo" />

    <div class="content container justify-content-md-center text-center">
      <h1>{{$t('page_not_found.labels.title')}}</h1>
      <h2>404 - {{$t('page_not_found.labels.msg')}}</h2>
      <router-link :to="{ name: 'home'}" class="btn btn-info mt-4">
        {{$t('page_not_found.actions.redirect')}}
      </router-link>
    </div>
    <div class="version col-1 offset-8 mt-4">
        {{ version }}
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'
export default {
    computed: {
        ...mapState({
            version: (state) => state.app.version,
        }),
    },
}
</script>

<style lang="scss">
@import '~@/assets/scss/variables';

.page-not-found {
    padding-top: 2rem;
    position: relative;
    background-color: $primary-light;
    height: 100vh;
    min-height: 100vh;
    color: $miwhite;

    .logo {
        display:block;
        margin-left:auto;
        margin-right:auto;

        max-width: 21rem;
        margin-bottom: 2rem;
    }

    .version {
        color: $grey;
    }
}
</style>
