import axios from 'axios'
import app from "@/main"
import { notify } from "@kyvg/vue3-notification";
import {i18n} from '@/config/i18n-config'

const { t } = i18n.global

axios.defaults.headers['Content-Type'] = 'application/json'
axios.defaults.baseURL = process.env.VUE_APP_API_BASEURL

axios.interceptors.response.use(
    response => response,
    error => {
        // log error in console
        app.$log.error(error)

        //notify the user that an error occured (the details must be handled more specifically in the concerned page or component)
        notify({type: "error", text: t('errors.generic')})

        // Do something with response error
        return Promise.reject(error);
    }
);