// All app filters
const initialState = {
    filters: {},
}

export default {
    namespaced: true,
    state: Object.assign({}, initialState),
    mutations: {
        set_filters(state, filters) {
            state.filters = filters
        },
    },
    actions: {
        setFilters({ commit }, filters) {
            commit('set_filters', filters)
        },
    },
}
