<template>
    <li class="nav-item">
        <a v-b-toggle="id" class="nav-link">
            <app-icon :icon="icon" />
            <span class="nav-item-text">{{ text }}</span>
            <app-icon icon="caret-down" />
        </a>
        <div :id="id" class="collapse-nav-items list-unstyled">
            <slot></slot>
        </div>
    </li>
</template>

<script>
export default {
    name: 'app-sidebar-toggle-item',
    props: {
        icon: String,
        id: String,
        text: String,
    },
}
</script>
