<template>
    <div>
        <button @click="toggleModal" class="btn btn-outline-danger btn-sm">
            <slot name="btn-text">
                {{ $t('_global.actions.delete') }}
            </slot>
        </button>

        <app-modal 
            :id="id"
            :ok="ok"
            :ok-title="okTitle || $t('_global.actions.delete')"
            :cancel-title="cancelTitle || $t('_global.actions.cancel')"
            @close="toggleModal" 
            :modalActive="modalActive">
                <template v-slot:modal-title>
                    <slot name="confirm-title">
                        {{ $t('_global.labels.delete_title') }}
                    </slot>
                </template>

                <template v-slot:modal-body>
                    <p class="texte">
                        <slot name="confirm-body">

                        </slot>
                    </p>
                </template>
                
        </app-modal>



    </div>
</template>

<script>
import { ref } from 'vue';

export default {
    name: 'app-delete-confirm-button',
    props: ['id', 'ok', 'okTitle', 'cancelTitle', 'size'],
    setup() {
    const modalActive = ref(false);

    const toggleModal = () => {
      modalActive.value = !modalActive.value;
    };

    return { modalActive, toggleModal};
  },
}
</script>

<style scoped>
    .modal-content {
        color: black;
    }

    .modal-header {
        font-weight: bold;
    }

    button {
        margin-left: 1%;
    }

    .texte {
        margin-top: 16px;
        text-align: center;
    }
</style>
