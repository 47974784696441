import axios from 'axios'
import { getAbsoluteUrl } from '@/services/url-service'

export const apiLogin = ({ username, password }) => {
    return axios.post('/users/login', {
        email: username,
        password: password,
    })
}

export const apiLoginToken = ({id_token}) => {
    return axios.get('/users/loginB2C', {
        headers: {Authorization: `Bearer ${id_token}`}
    })
}

export const apiLogout = () => {
    return axios.post('/api/AdminUsers/logout')
}

export const forgetPassword = (email) => (
    axios.post('/reset-password/init', {
        email
    })
)

export const resetPassword = ({ access_token, password }) => (
    axios.put(`/reset-password/finish`, {
        password: password,
        resetKey: access_token
    })
)
