import api from '@/api/roles-api'
import { createEntityModule } from '@/store/bases/base-entity-module'
import app from '@/main'

export default createEntityModule({
    namespaced: true,
    initialState: {
        id: null,
        key: '',
        title: '',
        url: '',
        error: null,
    },
    create: api.create,
    update: api.update,
    getById: api.getById,

    actions: {
        async save({ commit }, entity) {
            try {

                const toSave = Object.assign({}, entity, {
                    id: undefined, // id cannot be updated or inserted
                    error: undefined
                })
                

                let savedEntity = entity

                if (entity.id > 0) {
                    // update
                    await api.update(entity.id, toSave)

                } else {
                    // create
                    const response = await api.create(toSave)
                    savedEntity = response.data
                }

                commit('set_item', savedEntity)

                // update all state
                commit('roles/put_role', {
                    id: savedEntity.id,
                    role: savedEntity,
                }, { root: true })

                return savedEntity
            } catch (ex) {
                app.$log.error(ex)
                const error = ex.response.data.error
                console.log(ex.response.data.error)
                commit('set_error', error)

                return undefined
            }
        },
        async remove({ commit }, id) {
            try {
                const response = await api.remove(id)
                commit('reset')

                // update all state
                commit('roles/remove_role', { id }, { root: true })
                commit('auth/remove_role', { id }, { root: true })

                return response.data
            } catch (ex) {
                commit('set_error', ex.response.data)
            }
        }
    }
})