<template>
    <i :class="['icon', 'fas', icon_class]" :title="icon_title" aria-hidden="true"></i>
</template>

<script>
export default {
    name: 'app-icon',
    props: ['icon', 'title'],
    computed: {
        icon_class() {
            return `fa-${this.icon}`
        },
        icon_title() {
            return this.title || this.icon
        },
    }
}
</script>
<style lang="scss">
@import '~@/assets/scss/variables';

.icon-red {
    color: $red;
}
.icon-orange {
    color: $orange;
}
.icon-green {
    color: $green;
}
.icon-yellow {
    color: $yellow;
}

</style>

