const Gui = () => import(/* webpackChunkName: "gui" */ './BaseGui')

const Home = () => import(/* webpackChunkName: "gui" */ './HomePage')
const UsersList = () => import(/* webpackChunkName: "gui" */ './users/UsersListPage')
const UsersCreate = () => import(/* webpackChunkName: "gui" */ './users/UsersCreatePage')
const UsersEdit = () => import(/* webpackChunkName: "gui" */ './users/UsersEditPage')
const UsersInvite = () => import(/* webpackChunkName: "gui" */ './users/UsersInvitePage')

const MyAccount = () => import(/* webpackChunkName: "gui" */ './MyAccountPage')

const RolesList = () => import(/* webpackChunkName: "gui" */ './roles/RolesListPage')
const RolesEdit = () => import(/* webpackChunkName: "gui" */ './roles/RolesEditPage')
const RolesCreate = () => import(/* webpackChunkName: "gui" */ './roles/RolesCreatePage')

export default [
    { path: '/', component: Gui, meta: { restrictedTo: '$authenticated' }, children: [
        { path: '', component: Home, name: "home", meta: { restrictedTo: '$authenticated' } },
        { path: 'myaccount', component: MyAccount, name: "gui-myaccount", meta: { restrictedTo: '$authenticated' } },

        { path: 'users', component: UsersList, name: "gui-users-list", meta: { restrictedTo: 'admin' } },
        { path: 'users/new', component: UsersCreate, name: "gui-users-create", meta: { restrictedTo: 'admin' } },
        { path: 'users/:id', component: UsersEdit, name: "gui-users-edit", meta: { restrictedTo: 'admin' } },
        { path: 'users/invite', component: UsersInvite, name: "gui-users-invite", meta: { restrictedTo: 'admin' } },

        { path: 'roles', component: RolesList, name: "gui-roles-list", meta: { restrictedTo: 'admin' } },
        { path: 'roles/new', component: RolesCreate, name: "gui-roles-create", meta: { restrictedTo: 'admin' } },
        { path: 'roles/:id', component: RolesEdit, name: "gui-roles-edit", meta: { restrictedTo: 'admin' } },
    ] },
]
