<template>
    <div class="card-view">
        <vue-element-loading :active="isLoading" />

        <div class="cards">
            <div class="row first">
                <div class="d-flex flex-row-reverse">
                    <div class="col-5 ml-auto mb-3">
                        <form v-on:submit.prevent="provideItems" class="form-group" :label="$t('_global.labels.search')" >
                        <div class="input-group">
                            <label class="labelTest" for="filter" >{{$t('_global.labels.search')}}</label>
                            <input id="filter" class="form-control"
                                v-model="filter"
                                :placeholder="$t('_global.labels.search_example')"
                            />
                                <button type="submit" class="btn btn-primary">
                                    {{ $t('_global.actions.filter') }}
                                </button>
                        </div>
                        </form>
                    </div> 
                </div>
            </div>

            
                <div class="cardContainer" v-for="item in entries" v-bind:key="item">
                    <router-link :to="{ name: redirectionClick, params: { id: item.id, name: item.name } }">
                        <div class="card cardSpace">
                            <img src="@/assets/imgs/nfc-tag.png" class="card-img-top" alt="...">
                            <div class="card-body">
                                <h2 class="card-text deviceTitle">{{item.name}}</h2>
                            </div>
                        </div>
                    </router-link>
                </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'app-card-view',

    props: {
        provider: Function,
        redirectionClick: String
    },
    

    beforeMount() {
        this.provideItems();
    },

    data () {
        return {
            isLoading: false,
            pages: 1,
            sortDesc: "false",
            currentSort: "ASC",
            entries: [],
            filter: []
        }
    },
    methods: {
        async provideItems() {
            const filters = {};
            this.entries = await this.provider(filters);
        }
    }

}

</script>
<style scoped>
    .cards {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        align-items: center;
        justify-content: center;
    }

    .cardSpace {
        color: blue;
        width: 18rem;
        margin: 1rem 1rem 1rem 1rem;
    }

    .cardContainer :hover {
        transform: scale(1.1);
        border: blue 0.1rem solid;
    }

    .cardContainer div :hover {
        transform: scale(1.0);
        border: none;
    }

    .deviceTitle {
        text-align: center;
    } 

    .cardContainer {
        transition: transform .5s;
    }

    .labelTest {
        margin-right: 5%;
        display: flex;
        align-items: center;
    }

    .first {
        flex-basis: 100%;
        justify-content: start;
        margin-right: 0.75rem;
    }
</style>
