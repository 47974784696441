const _initialState = {
    items: [],
    totalRows: 0,
    // TODO remove
    filter: "",
    filters: {},
    currentPage: 1,
    perPage: 4,
    error: undefined,
}

export const createEntitiesModule = ({
    initialState,
    fetchAll,
    countAll,
    remove,
    actions,
    mutations,
    ...rest
}) => ({
    state: Object.assign({}, _initialState, initialState),
    mutations: {
        set_page(state, currentPage) {
            state.currentPage = currentPage
        },
        set_items(state, items) {
            state.items = items
        },
        set_total_rows(state, totalRows) {
            state.totalRows = totalRows
        },
        set_filter(state, filter) {
            state.filter = filter
        },
        set_filters(state, filters) {
            state.filters = filters
        },
        set_error(state, error) {
            state.error = error
        },
        remove_item(state, id) {
            const index = state.items.findIndex((item) => item.id === id)

            if (index >= 0) {
                state.items = state.items.splice(index, 1)
            }
        },
        ...mutations,
    },
    actions: {
        setCurrentPage({ commit }, currentPage) {
            commit('set_page', currentPage)
        },
        setFilter({ commit }, filter) {
            commit('set_filter', filter)
        },
        setFilters({ commit }, filters) {
            commit('set_filters', Object.assign({}, filters))
        },
        async fetchNCountAll({ commit }, filters = {}) {
            try {
                const response = await Promise.all([
                    fetchAll(filters),
                    countAll(filters.where || {}),
                ])

                const [ itemsResponse, totalRowsResponse ] = response
                const items = itemsResponse.data
                const total_rows = totalRowsResponse.data.count

                // commit
                commit('set_items', items)
                commit('set_total_rows', total_rows)

                return {
                    items,
                    totalRows: total_rows,
                }
            } catch (ex) {
                console.log(ex)

                commit('set_error', ex)

                return {
                    error: ex,
                }
            }
        },
        async fetchAll({ commit }, filters = {}) {
            try {
                const response = await fetchAll(filters)
                const items = response.data

                // commit
                commit('set_items', items)
            
                return {
                    items
                }
            } catch (ex) {
                this.$log.error(ex)
                commit('set_error', ex)

                throw ex;
            }
        },

        async remove({ commit }, id) {
            try {
                const response = await remove(id)
                commit('remove_item', id)

                return response.data
            } catch (ex) {
                commit('set_error', ex.response.data)
            }
        },
        ...actions,
    },
    ...rest
})
