import { createStore } from 'vuex'

const requireComponent = require.context(
    './modules', false, /[\w-]+[-]module\.js$/
)

const modules = {}

requireComponent.keys().forEach(fileName => {
    const moduleStore = requireComponent(fileName).default
    const moduleName = fileName
        .replace(/^\.\//, '')
        .replace(/\.\w+$/, '')
        .replace('-module', '') // remove module suffix
        .replace(/([-]\w)/g, m => (m[1].toUpperCase())) // snake-case to camelCase

    modules[moduleName] = moduleStore
})

const store = createStore({
    strict: true,
    modules,
})

export default store