import { createEntitiesModule } from '@/store/bases/base-entities-module'
import api from '@/api/users-api'

export default createEntitiesModule({
    namespaced: true,
    initialState: {
        perPage: 6,
    },
    fetchAll: api.fetchAll,
    countAll: api.countAll,
    remove: api.remove,

    mutations: {
        remove_user(state, id) {
            const index = state.items.findIndex((elm) => elm.id === id)

            if (index >= 0) {
                state.items = state.items.slice(index, index + 1)
            }
        },
    }
})