import { createI18n } from 'vue-i18n'
import axios from 'axios'
import { setLocale } from '@vee-validate/i18n'
//import fr from '@/assets/i18n/fr

// always loads en because it is used as fallback lang
import en from '@/assets/i18n/en'

const loadedLanguages = ['en']

const i18n = new createI18n({
    locale: 'en',
    fallbackLocale: 'en',
    messages: {
        en,
    },
})

const setI18nLanguage = (lang) => {
    i18n.global.locale = lang
    setLocale(lang)
    axios.defaults.headers.common['Accept-Language'] = lang
    document.querySelector('html').setAttribute('lang', lang)
    return lang
}

export const loadLanguageAsync = (lang) => {
    if (i18n.global.locale !== lang) {
        if (!loadedLanguages.includes(lang)) {
            return import(/* webpackChunkName: "lang-[request]" */ `@/assets/i18n/${lang}`).then(msgs => {
                i18n.global.setLocaleMessage(lang, msgs.default)
                loadedLanguages.push(lang)

                return setI18nLanguage(lang)
            })
        }

        return Promise.resolve(setI18nLanguage(lang))
    }

    return Promise.resolve(lang)
}

// config for user
let lang = navigator.userLanguage || navigator.language;

if (lang.indexOf('-') !== -1) {
    lang = lang.split('-')[0];
}

loadLanguageAsync(lang)

export {i18n, lang};