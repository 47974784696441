<template>
    <div>
        <Field type="text" class="form-control"
            :name="name"
            v-bind="$attrs"
            v-model="vxinput"
            :data-vv-as="as"
            :rules="rules"
            autocomplete="on"
        />
    </div>
</template>

<script>
import { Field } from 'vee-validate'
export default {
    name: 'app-vx-input',
    inheritAttrs: false,
    props: ['namespace', 'name', 'value', 'rules', 'as'],
    computed: {
        vxinput: {
            get() {
                return this.$store.state[this.namespace][this.name]
            },
            set(value) {
                this.$store.commit(`${this.namespace}/set_value`, {
                    key: this.name,
                    value: value,
                })
            }
        },
    },
    components: {
        Field
    }
}

</script>

<style scoped>
    .errorInput {
        border-color: #DC356F;
    }
</style>