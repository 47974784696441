import { createEntitiesModule } from '@/store/bases/base-entities-module'
import api from '@/api/roles-api'

export default createEntitiesModule({
    namespaced: true,
    initialState: {
        perPage: 10,
    },
    fetchAll: api.fetchAll,
    countAll: api.countAll,
    remove: api.remove,

    actions: {
        async remove({ commit }, id) {
            try {
                const response = await api.remove(id)
                commit('remove_item', id)
                commit('auth/remove_role', { id }, { root: true })

                return response.data
            } catch (ex) {
                commit('set_error', ex.response.data)
            }
        }
    },

    mutations: {
        put_role(state, { id, role }) {
            // find the role and update it
            const index = state.items.findIndex((elm) => elm.id === id)

            if (index >= 0) {
                state.items[index] = role
            } else {
                state.items = [...state.items, role]
            }
        },
        remove_role(state, { id }) {
            // find the role and remove it
            const index = state.items.findIndex((item) => item.id === id)

            if (index >= 0) {
                state.items = state.items.splice(index, 1)
            }
        },
    },
})
