import packageInfos from '../../../package.json';

export default {
    namespaced: true,
    state: {
        sidebar_is_open: false,
        version: `v${packageInfos.version}`,
    },

    mutations: {
        set_sidebar_is_open(state, isOpen) {
            state.sidebar_is_open = isOpen
        }
    },

    actions: {
        toggleSidebar({ commit, state }) {
            commit('set_sidebar_is_open', !state.sidebar_is_open)
        }
    }
}