import axios from 'axios'
import { mapActions } from 'vuex'
import { createBackendApiCalls } from './base-api'

const api = createBackendApiCalls({
    endpoint: 'users',
    methods: [
        'fetchAll',
        'getById',
        'countAll',
        'remove',
        'create',
    ],
})

api.me = async () => (
    await axios.get(`/users/me`)
)

api.update = async (id, user) => (
    await axios.patch(`/users/${id}`, {
        ...user,
    })
)

api.associateRoles = (userId, rolesIds) => (
    axios.post(`/users/setRoles`, {
        userId,
        rolesIds,
    })
)

api.associatePublicRole = (userId, roleId) => (
    axios.post(`/users/setRoles`, {
        userId,
        roleId,
    })
)

api.inviteUser = (email, rolesIds) => (
    axios.post('/users/invite', {
        email,
        rolesIds
    })
)

api.createInvited = async (createKey, user) => {
    await axios.post('/users/invite/create/' + createKey, {
        ...user
    })
}

export default api

