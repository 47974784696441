<template>
    <li class="nav-item">
        <router-link class="nav-link"
            v-bind="$attrs"
        >
            <app-icon :icon="icon" />
            <span class="nav-item-text">{{ text }}</span>
        </router-link>
        <slot name="complement"></slot>
    </li>
</template>

<script>
export default {
    inheritAttrs: false,
    name: 'app-sidebar-item',
    props: [
        'text',
        'icon',
    ],
}

</script>

<style lang="scss">
@import '~@/assets/scss/variables';
.sidebar {
    &.is-open,
    &:hover {
        .nav-item {
            a, .toggle-button {
                text-align: left;
                .nav-item-text {
                    color: $white;
                    display: inline;
                }
            }
        }

        .collapse-nav-items.show, .collapse-nav-items.collapsing {
            .nav-item {
                display: inline;
            }
        }
    }

    .nav-item {
        width: 100%;
        cursor: pointer;

        &:hover {
            background: $sidebar-item-hover-color;
            i {
                color: $sidebar-item-text-hover-color;
            }
        }

        .collapse-nav-items {
            padding-left: 2em;

            .nav-item {
                display: none;

                .nav-link {
                    border-left: 1px solid $primary;
                }
            }
        }

        a, .toggle-button {
            padding-left: 1.5rem;
            white-space: nowrap;
            overflow: hidden;
            color: $sidebar-item-text-color;

            &:hover {
                color: $sidebar-item-text-hover-color;
                text-decoration: none;
            }

            &.active, &.router-link-active {
                border-bottom: 1px solid $sidebar-item-text-active-color;
                i {
                    color: $sidebar-item-text-active-color;
                }
            }

            .nav-item-text {
                display: none;
                margin-left: 1rem;
            }

            i {
                color: $sidebar-item-text-color;
            }
        }

        .fa-caret-down::before {
            margin-left: 0.5em;
        }
    }
}
</style>
