import axios from 'axios'
import env from '@/config/env-config'
import { getAccessToken } from '@/services/auth-service'


export const createBackendApiCalls = ({
    endpoint,
    methods,
}) => {
    const apis = {}

    if (methods.includes('fetchAll')) {
        apis.fetchAll = async (filter) => (
            await axios.get(buildJsonURl(`/${endpoint}`, {
                filter,
            }))
        )
    }

    if (methods.includes('countAll')) {
        apis.countAll = async (where) => (
            await axios.get(buildJsonURl(`/${endpoint}/count`, {
                where,
            }))
        )
    }

    if (methods.includes('getById')) {
        apis.getById = async ({ id, filter = {} }) => (
            await axios.get(buildJsonURl(`/${endpoint}/${id}`, { filter }))
        )
    }

    if (methods.includes('create')) {
        apis.create = async (data) => (
            await axios.post(`/${endpoint}`, data)
        )
    }

    if (methods.includes('update')) {
        apis.update = async (id, data) => (
            await axios.put(`/${endpoint}/${id}`, {
                ...data,
            })
        )
    }

    if (methods.includes('remove')) {
        apis.remove = async (id) => (
            await axios.delete(`/${endpoint}/${id}`)
        )
    }

    return apis
}

export const buildUrl = (url, data) => {
    const paramUrl = getString(data)

    return `${url}?${paramUrl}`
}

export const encodeJSON = (data) => {
    return encodeURIComponent(JSON.stringify(data))
}

export const buildJsonURl = (url, data) => {
    const dataUrl = []

    // encode first level as key=value
    Object.keys(data).forEach((k) => {
        const value = encodeJSON(data[k])
        dataUrl.push(`${k}=${value}`);
    })

    const params = dataUrl.join('&')

    return `${url}?${params}`
}

export const buildAbsoluteLink = (url, data) => {
    // add access_token
    const access_token = getAccessToken();
    return buildUrl(`${env.getBaseAPIUrl()}${url}`, {
        access_token: access_token.id,
        ...data,
    })
}

function getString(o) {
    function iter(o, path) {
        if (Array.isArray(o)) {
            o.forEach(function (a, idx) {
                iter(a, `${path}[${idx}]`);
            });
            return;
        }
        if (o !== null && typeof o === 'object') {
            Object.keys(o).forEach(function (k) {
                iter(o[k], `${path}[${k}]`);
            });
            return;
        }
        data.push(`${path}=${o}`);
    }

    var data = [];
    Object.keys(o).forEach(function (k) {
        iter(o[k], k);
    });
    return data.join('&');
}