<template>
    <div>
        <nav class="navbar fixed-top navbar-expand-lg navbar-dark" id="navbar" v-bind:style="{ height: computedHeight }" toggleable >
            <div class="container-fluid">
                <a class="navbar-brand" >
                    <router-link :to="{ name: homeLink }">
                        <img class="logo" src="@/assets/imgs/thuasne-logo.png" alt="thuasne logo" />
                    </router-link>
                </a>
                <button v-on:click="changeHeight" class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                    <span class="navbar-toggler-icon"></span>
                </button>
                <div class="collapse navbar-collapse" id="navbarSupportedContent">
                    <ul class="navbar-nav w-100">
                        
                        <li class="nav-item"
                            v-for="link in myLinks"
                            :key="link.id"
                        >
                            <a class="nav-link" v-bind:href="link.url">{{ link.title }}</a>
                        </li>  
                        <li class="nav-item dropdown ms-auto" v-if="user">
                                <a class="nav-link dropdown-toggle" href="#" id="navbarDropdown" role="button" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                    <app-icon classicon="user-circle" title="user" />
                                    {{ user.username }}
                                </a>
                                <ul class="dropdown-menu dropdown-menu-end" aria-labelledby="navbarDropdown">   
                                    <li class="firstLine">
                                            <router-link class="dropdown-item" :to="{ name: 'gui-myaccount' }">
                                                <app-icon icon="info" :title="$t('myaccount.labels.nav_title')" />
                                                {{ $t('myaccount.labels.nav_title') }}
                                            </router-link>
                                    </li>
                                    <li>
                                            <router-link class="dropdown-item" :to="{ name: 'logout' }">
                                                <app-icon icon="sign-out-alt" :title="$t('login.actions.logout')" />
                                                {{ $t('login.actions.logout') }}
                                            </router-link>
                                    </li>

                                    <hr class="dropdown-divider">

                                    <li style="text-align: center"><p>{{ version }}</p></li>
                                </ul>
                        </li>
                    </ul>
                </div>
            </div>
        </nav>
    </div>
</template>

<script>
import { mapState } from 'vuex'

export default {
    name: 'app-navbar',
    props: ['homeLink'],
    data() {
        return {
            height: '4.5rem',
            openHeight: '12rem',
            closeHeight: '4.5rem'
        }
    },
    computed: {
        ...mapState({
            user: state => state.auth.user,
            version: state => state.app.version,
        }),
        myLinks() {
            if (this.user.roles) {
                return [...this.user.roles].sort((a, b) => a.title.localeCompare(b.title))
            }

            return []
        },
        computedHeight() {
            return this.height;
        }
    },
    methods: {
        changeHeight() {
            if (this.height == this.closeHeight) {
                this.height = this.openHeight;
            }
            else if (this.height == this.openHeight) {
                this.height = this.closeHeight;
            }
            
        }
    }
}
</script>

<style lang="scss">
@import '~@/assets/scss/variables';

#navbar {

    padding: 0;
    height: $navbar-height;
    background-color: $primary;

    .logo {
        height: $navbar-image-height;
    }

    .menu {
        color: $white;
    }

    .user-menu {
        background: none;
        border: none;
    }
    .link {
        color: $grey;
        text-decoration: none;
    }
    .link:hover {
        color: $white;
    }
}



</style>
