<template>
    <div class="list-view">
        
        <vue-element-loading :active="isLoading" />

        <div class="row">
            <div class="d-flex flex-row-reverse">
                <div class="col-5 ml-auto mb-3">
                    <form v-on:submit.prevent="provideItems" class="form-group" :label="$t('_global.labels.search')" >
                    <div class="input-group">
                        <label class="labelTest" for="filter" >{{$t('_global.labels.search')}}</label>
                        <input id="filter" class="form-control"
                            v-model="filter"
                            :placeholder="$t('_global.labels.search_example')"
                        />
                            <button type="submit" class="btn btn-primary">
                                {{ $t('_global.actions.filter') }}
                            </button>
                    </div>
                    </form>
                </div>
                
            </div>
        </div>
        
        <div class="table-responsive">
            <table id="dtBasicExample" class="table table-hover table-striped align-middle">
                <thead>
                    <tr>
                    <th scope="col" class="th-sm" v-for="(field, index) in fields" v-bind:key="index">
                        {{field.label}} 
                        <span v-on:click="setFieldSort(field)" v-if="field">
                            <i v-if="field.sort == 'ASC'" class="fa fa-arrow-up-a-z"></i>
                            <i v-if="field.sort == 'DESC'" class="fa fa-arrow-up-z-a"></i>
                        </span>
                    </th>
                    
                    </tr>
                </thead>
                
                <tbody>
                    <tr v-for="(item, i) in sortedEntries" v-bind:key="i">
                        <td v-for="(field, index) in fields" v-bind:key="`${field}-${index}-${i}`">
                            <slot :index="i" :item="item" :name="field.name" :provide="provideItems">
                            {{ 
                                field.formatter
                                ? field.formatter(item[field.name] , field.name)
                                : item[field.name] 
                            }}
                            </slot>
                        </td>
                    </tr>
                </tbody>
            </table>
            <v-pagination
                v-model="currentPage"
                :pages="allPages"
                :range-size="1"
                active-color="#DCEDFF"
                @update:model-value="provideItems"
            />
            
        </div>
    </div>

</template>

<script>
import VPagination from "@hennge/vue3-pagination";
import "@hennge/vue3-pagination/dist/vue3-pagination.css";

export default {
    name: 'app-list-view',
    inheritAttrs: false,
    props: {
        fields: Object,
        module: String,
        provider: Function,
        sortBy: String,
    },
    beforeMount() {
        this.provideItems();
    },
    data () {
        return {
            isLoading: true,
            perPage: this.$store.state[this.module].perPage,
            state: this.$store.state[this.module],
            pages: 1,
            sortDesc: "false",
            currentSort: 'ASC',
            test: true,
            currentField: this.fields[0],
            entries: []
        }
    },
    computed: {
        fielder: {
            get() { return this.currentField },
            set(field) {
                this.currentField = field
            }
        },
        totalRows() {
            return this.state.totalRows
        },
        currentPage: {
            get() {return this.state.currentPage},
            set(page) {
                this.$store.commit(`${this.module}/set_page`, page)
            }
        },
        filter: {
            get() { return this.state.filter },
            set(filter) {
                this.$store.commit(`${this.module}/set_filter`, filter)
            }
        },
        allPages() {
            // return number of pages according to the number per page
            if ((this.totalRows % this.perPage) != 0) {
                return Math.floor(this.totalRows / this.perPage) + 1
            } else {
                return Math.floor(this.totalRows / this.perPage)
            }
        },
        sortASC() {
            return this.fielder.sort == 'ASC';
        },
        sortedEntries() {
            let sortedEntries = [...this.entries]
            if (this.fielder.sort == 'ASC') {
                sortedEntries.sort((a,b) => {
                    let fa = a[this.fielder.name].toLowerCase(), fb = b[this.fielder.name].toLowerCase();
                    if (fa < fb) {
                        return -1
                    }
                    if (fa > fb) {
                        return 1
                    }
                    return 0
                });
                this.fielder.sort = 'DESC'
            } else {
               sortedEntries.sort((a,b) => {
                    let fa = a[this.fielder.name].toLowerCase(), fb = b[this.fielder.name].toLowerCase();
                    if (fa < fb) {
                        return 1
                    }
                    if (fa > fb) {
                        return -1
                    }
                    return 0
                });
                this.fielder.sort = 'ASC'
            }
            
            return sortedEntries;
        }
    },
    methods: {
        async provideItems() {

            // Filling items variable
            this.isLoading = true

            // pagination + order (always provided)
            const filters = {
                limit: this.perPage,
                skip: (this.currentPage - 1) * this.perPage,
            }

            if (this.sortBy !== null) {
                filters.order = `${this.sortBy} ${this.sortDesc ? 'DESC' : 'ASC'}`
            }

            const res = await this.provider(filters)
            this.isLoading = false
            
            this.entries = res.items;
        },
        onFiltered() {
            this.currentPage = 1;
            this.provideItems();
        },
        setFieldSort(field) {
            this.fielder = field;
            this.test = !this.test;
        },
    },
    components: {
        VPagination
    }
}
</script>
<style scoped>
    .labelTest {
        margin-right: 10%;
        display: flex;
        align-items: center;
    }

    thead {
        border-top: solid rgba(0, 0, 0, 0.1) 1px;
        border-bottom: solid rgba(0, 0, 0, 0.1) 2px;
        
    }

    tbody {
        border-top: none;
    }
</style>
