<template>
    <div>
    <multiselect
        :options="options"
        mode="tags"
        :id="id"
        v-model="selected"
        :close-on-select="false"
        :searchable="true"
        :label="label"
        valueProp="id"
        :object="true"
        @change="valueChanged($event)"
        :select-label="$t('_global.labels.select')"
        :placeholder="$t('_global.labels.select')"
        :select-group-label="$t('_global.labels.select_group')"
        :selected-label="$t('_global.labels.selected')"
        :deselect-label="$t('_global.labels.deselect')"
        :deselectGroup-label="$t('_global.labels.deselect_group')"
    >
    </multiselect>
    
    </div>

</template>

<script>
import Multiselect from '@vueform/multiselect'

export default {
    inheritAttrs: false,
    name: 'app-select',
    props: ['id', 'label', 'value', 'options'],
    components: { Multiselect },
    data() {
        return {
            selected: [...this.value]
        };
    },
    methods: {
        valueChanged(event) {
            this.$emit('selectedChange', event)
        }
    }
}
</script>

<style src="@vueform/multiselect/themes/default.css"></style>