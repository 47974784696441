import '@/config/api-config'
import '@/config/logger-config'
import '@/config/cookie-config'
import '@/config/validator-config'
import '@/components/_globals'
import {i18n} from '@/config/i18n-config'
import router from '@/config/router-config'
import store from '@/store'

export default {
    router,
    store,
    i18n
}
