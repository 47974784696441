<template>
    <div class="chart-container">
        <button @click="toDay"> Day </button>
        <Line
            :data="chartData"
            :options="chartOptions"
            :key="myKey"
        />
    </div>
</template>

<script>
import { Line, Scatter } from 'vue-chartjs'
import {
    Chart as ChartJS,
  Title,
  Tooltip,
  Legend,
  LineElement,
  LinearScale,
  PointElement,
  CategoryScale,
  Plugin,
  TimeScale
} from 'chart.js'

import 'chartjs-adapter-moment'

ChartJS.register(
  Title,
  Tooltip,
  Legend,
  LineElement,
  LinearScale,
  PointElement,
  CategoryScale,
  TimeScale
)

export default {
    name: 'app-line-chart',
    computed: {
        chartData() {
            return {
                datasets: [
                    {
                    label: 'Temperature',
                    backgroundColor: '#f87979',
                    data: [
                        {x: '2021-11-06', y: 20}, 
                        {x: '2021-11-07', y: 30},
                        {x: '2021-11-08', y: 2},
                        {x: '2021-11-09', y: 40},
                        {x: '2021-11-10', y: 60},
                        {x: '2021-11-11', y: 70},
                        {x: '2021-11-12', y: 80},
                    ],
                    }
                ]
            };
        },
        chartOptions() {
            return {
                responsive: true,
                maintainAspectRatio: true,
                scales: {
                    x: {
                        type: 'time',
                        time: {
                            unit: 'day'
                        }
                    },
                    y: {
                        beginAtZero: true
                    }

                }
                
            };
        },
        myKey() {
            return 2
        }
    },
    data() {
        return {
        }
    },
    methods: {
        toDay() {
            console.log(this.chartData)
            console.log(this.chartOptions)
            this.chartOptions.scales.x.time.unit = 'month'
            this.chartData.datasets[0].data[0].y = 1000;
            console.log(this.chartOptions)
            console.log(this.chartData.datasets[0].data[0].y)
            this.myKey = 0
        }
    },
    components: {
        Line, Scatter
    },
}
</script>

<style lang="scss">
@import '~@/assets/scss/variables';

.chart-container {
    margin: 1rem 1rem 1rem 1rem;
}


</style>

