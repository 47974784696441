<template>
    <div class="app-sidebar" @mouseover="toggleSidebarHover()" @mouseout="toggleSidebarHover()">
        <aside :class="{ 'sidebar': true, 'is-open': sidebarOpened }">
            <ul class="nav flex-column">
                <li class="nav-item">
                    <button class="toggle-button nav-link btn btn-link" href="#"
                        v-on:click="toggleSidebarClick()"
                    >
                        <app-icon icon="bars"/>
                    </button>
                </li>
                <slot></slot>
            </ul>
        </aside>
    </div>
</template>

<script>
import { mapActions, mapState } from 'vuex'

export default {
    name: 'app-sidebar',
    data() {
        return {
            hoverBlocked: false,
        }
    },
    computed: {
        ...mapState({
            sidebarOpened: (state) => state.app.sidebar_is_open,
        }),
    },
    methods: {
        toggleSidebarHover() {
            if (!this.hoverBlocked) {
                this.toggleSidebar()
            }
        },
        toggleSidebarClick() {
            this.hoverBlocked = !this.hoverBlocked
        },
        ...mapActions({
            toggleSidebar: 'app/toggleSidebar'
        })
    }
}

</script>

<style lang="scss">
@import '~@/assets/scss/variables';

.app-sidebar {
    .sidebar {
        z-index: 1001;
        min-height: 100vh;
        position: fixed;
        top: $navbar-height;
        left: 0;
        width: $sidebar-width;
        transition: all 300ms cubic-bezier(0.65, 0.05, 0.36, 1);
        will-change: left, width;
        box-shadow: inset -1px 0 10px rgba(0, 0, 0, 0.4);
        background: $sidebar-color;

        &.is-open,
        &:hover {
            width: $sidebar-width-open;
        }

        .nav {
            list-style: none;
        }
    }
}
</style>
