import axios from 'axios'
import store from '@/store'
import { VueCookieNext } from 'vue-cookie-next'

/**
 * @returns bool if the current user is authenticated
 */
export const isUserAuthenticated = () => (
    store.getters['auth/isAuthenticated']
)

/**
 * @returns bool if the current user has the role admin
 */
export const isUserAdmin = () => {
    const currentUser = store.getters['auth/user']

    if (currentUser === null) {
        return false
    }

    return isAdmin(currentUser)
}

/**
 * @returns string accessToken if founds, undefined else
 */
export const getAccessToken = () => {
    const storedAccessToken = localStorage.getItem('access-token')
    const cookieAccessToken = VueCookieNext.getCookie('access-token')

    if (storedAccessToken !== null) {
        return JSON.parse(storedAccessToken)
    }
    else if (cookieAccessToken !== null) {
        return cookieAccessToken
    }

    return undefined
}

/**
 * Store the accessToken in storage and set Authorization http header
 * @param {*} accessToken
 * @param {*} remember
 */
export const storeAccessToken = (accessToken, remember) => {
    // set header in axios
    axios.defaults.headers['Authorization'] = 'Bearer ' + accessToken

    // store in local storage in order to keep user signed in while navigating (full refresh)
    localStorage.setItem('access-token', JSON.stringify(accessToken))

    if (remember) {
        VueCookieNext.setCookie('access-token', JSON.stringify(accessToken))
    }
}

/**
 * Delete access token from all storage and unset the Authorization http header
 */
export const deleteAccessToken = () => {
    // remove http header
    axios.defaults.headers['Authorization'] = undefined

    // delete storage items
    localStorage.clear()

    // delete cookie
    VueCookieNext.removeCookie('access-token')
}

/**
 * Check if the given token is valid or not
 * @param {*} accessToken
 * @returns bool
 */
export const isValidAccessToken = (accessToken) => {
    /*if (accessToken.token !== undefined && accessToken.createdAt !== undefined && accessToken.ttl !== undefined)
    {
            const maxAlive = new Date(accessToken.createdAt)
            const now = new Date()

            // add ttl seconds
            maxAlive.setSeconds(maxAlive.getSeconds() + accessToken.ttl)
            return maxAlive > now
    }*/

    if (accessToken != '') {
        return true
    }
    return false
}

/**
 * The given user has the admin role ?
 * @param {*} user
 * @returns bool
 */
export const isAdmin = (user) => {
    if (user !== undefined && user.roles !== undefined) {
        const index = user.roles.findIndex(
            (role) => role.title === 'admin'
        )

        return index >= 0
    }

    return false
}

/**
 * Check if the current user has the given role
 * @param {*} role
 * @returns bool
 */
export const hasRole = (roleGiven) => {
    const user = store.getters['auth/user']

    if (user !== null
        && user !== undefined
        && user.roles !== undefined)
    {
        const index = user.roles.findIndex(
            (role) => role.title.toUpperCase() === roleGiven.toUpperCase()
        )

        return index >= 0
    }

    return false
}

export const tryToLogin = async () => {
    if (!isUserAuthenticated()) {
        const accessToken = getAccessToken();
        if (accessToken !== undefined
            && isValidAccessToken(accessToken))
        {
            await accessTokenLogin({ accessToken })
        }
    }
};


export const accessTokenLogin = (data) => {
    return store.dispatch('auth/accessTokenLogin', data);
}