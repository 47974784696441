export const createEntityModule = ({
    initialState,
    getById,
    create,
    update,
    remove,
    mutations,
    actions,
    ...rest
}) => ({
    state: Object.assign({}, initialState),
    mutations: {
        set_error(state, error) {
            state.error = error
        },
        set_item(state, item) {
            // auto map
            Object.keys(initialState).forEach(function (key) {
                if (Object.prototype.hasOwnProperty.call(item, key)) {
                    state[key] = item[key]
                }
            });
        },
        set_value(state, { key, value }) {
            state[key] = value
        },
        reset(state) {
            Object.assign(state, initialState)
        },

        ...mutations,
    },
    actions: {
        async getById({ commit }, data) {
            const response = await getById(data)
            commit('set_item', response.data)
        },

        async update({ commit }, entity) {
            const toSave = Object.assign({}, entity, {
                id: undefined, // id cannot be updated or inserted
            })

            // update
            await update(entity.id, toSave)

            commit('set_item', entity)

            return entity
        },

        async create({ commit }, entity) {
            const toSave = Object.assign({}, entity, {
                id: undefined, // id cannot be updated or inserted
            })

            // create
            const response = await create(toSave)
            const savedEntity = response.data

            commit('set_item', savedEntity)

            return savedEntity
        },

        async remove({ commit }, id) {
            try {
                const response = await remove(id)
                commit('reset')

                return response.data
            } catch (ex) {
                commit('set_error', ex.response.data)
            }
        },

        reset({ commit }) {
            commit('reset')
        },

        ...actions,
    },

    ...rest
})