import { isUserAdmin, isUserAuthenticated, hasRole, tryToLogin } from './auth-service'

export const authenticationRoleGuard = async (to, from, next) => {
    // try to auth
    await tryToLogin();
    const restrictions = [...new Set(to.matched.map(m => m.meta.restrictedTo))]
    let redirectTo = null;

    restrictions.forEach(restriction => {
        switch(restriction) {
            case '$authenticated':
                // check if user is logged in
                if (!isUserAuthenticated()) {
                    if (to.hash != "" && to.hash.includes("#id_token")) {
                        redirectTo = { name : 'loginWithToken',  query: {id_token: to.hash.replace("#id_token=", "")}}
                    } else {
                        // redirect to login page
                        redirectTo = { name: 'login' }
                    }  
                }
                break
            case '$unauthenticated': {
                if(isUserAuthenticated()) {
                    redirectTo = { name:'home' }
                }
                break;
            }
            case 'admin':
                // check if user is admin
                if (!isUserAdmin()) {
                    // redirect to home page
                    redirectTo = { name: 'home' }
                }
                break;
            case 'patient':
                if (!hasRole(restriction)) {
                    // redirect to home page
                    redirectTo = { name: 'home' }
                }
                break;
            case 'dm':
            case 'mkt':
                if (!hasRole(restriction) && !isUserAdmin()) {
                    // redirect to home page
                    redirectTo = { name: 'home' }
                }
                break;
        }
    })

    if (redirectTo) {
        return next(redirectTo)
    }

    next()
}