<template>
    <div class="mt-3">
        <label>
            {{ label }} <i v-if="isRequired" class="text-danger">*</i>
        </label>

        <slot name="input">
            <app-vx-input
                :id="id"
                :as="label"
                :name="name"
                :class="getClass"
                :namespace="namespace"
                v-bind="$attrs"
            />
        </slot>

        <p class="errors" v-if="name in errors">{{errors[name]}}</p>
        

        
    </div>
</template>

<script>
export default {
    name: 'app-vx-form-group-input',
    inheritAttrs: false,
    props: ['id', 'label', 'required', 'name', 'description', 'errors', 'namespace'],
    computed: {
        isRequired() {
            return this.required === '' || this.required
        },
        getClass() {
            if (this.name in this.errors) {
                return 'errorInput'
            }
            return ''
        }
    }
}
</script>
