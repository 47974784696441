import { createApp, h} from 'vue'
import App from './App'
import router from '@/config/router-config'
import store from '@/store'
import VueElementLoading from 'vue-element-loading'
import {i18n} from '@/config/i18n-config'
import Notifications from '@kyvg/vue3-notification'
import vueHeadful from 'vue-headful';
import VueLogger from 'vuejs3-logger'
import { VueCookieNext } from 'vue-cookie-next'
import {loggerOptions} from './config/logger-config'
import './config'
import 'bootstrap/dist/css/bootstrap.min.css'
import 'bootstrap/dist/js/bootstrap.bundle.min.js'



const app = createApp({
  render: () => h(App)
});
        

app.use(router);
app.use(store);
app.use(i18n)
app.use(VueCookieNext);

// Notification system
app.use(Notifications)

//const isProduction = process.env.NODE_ENV === 'production';
app.use(VueLogger, loggerOptions)

const requireComponent = require.context(
  './components', false, /App[\w-]+\.vue$/
)

requireComponent.keys().forEach(fileName => {
  const componentConfig = requireComponent(fileName).default
  app.component(componentConfig.name, componentConfig)
})

// Meta Helper
app.component('vue-headful', vueHeadful)
app.component('VueElementLoading', VueElementLoading)
app.mount("#app");

export default app;

