import router from '@/config/router-config'

export const getAbsoluteUrl = (route) => {
    const resetUrl = router.resolve(route)
    const baseUrl = getBaseUrl()
    return `${baseUrl}${resetUrl.href}`
}

export const getBaseUrl = () => {
    return window.location.origin
        ? `${window.location.origin}`
        : `${window.location.protocol}/${window.location.host}`;
}