const Patient = () => import(/* webpackChunkName: "patient" */ './BasePatient')
const Home = () => import(/* webpackChunkName: "patient" */ './HomePage')
const Dashboard = () => import(/* webpackChunkName: "patient" */ './DashboardPage')
const DeviceMonitoring = () => import(/* webpackChunkName: "patient" */ './DeviceMonitoring')
const Moments = () => import(/* webpackChunkName: "patient" */ './MomentsPage')
const Exercises = () => import(/* webpackChunkName: "patient" */ './ExercisesPage')
const Infos = () => import(/* webpackChunkName: "patient" */ './InfosPage')
const Monitoring = () => import(/* webpackChunkName: "patient" */ './MonitoringPage')
const Settings = () => import(/* webpackChunkName: "patient" */ './SettingsPage')

export default [
    { path: '/patient', component: Patient, meta: { restrictedTo: 'patient' }, children: [
        { path: '', component: Home, name: "patient", meta: { restrictedTo: 'patient' } },
        { path: 'dashboard', component: Dashboard, name: "patient-dashboard", meta: { restrictedTo: 'patient' } },
        { path: 'deviceMonitoring', component: DeviceMonitoring, name: "patient-device-monitoring", meta: { restrictedTo: 'patient' } },
        { path: 'moments', component: Moments, name: "patient-moments", meta: { restrictedTo: 'patient' } },
        { path: 'exercises', component: Exercises, name: "patient-exercises", meta: { restrictedTo: 'patient' } },
        { path: 'infos', component: Infos, name: "patient-infos", meta: { restrictedTo: 'patient' } },
        { path: 'monitoring', component: Monitoring, name: "patient-monitoring", meta: { restrictedTo: 'patient' } },
        { path: 'settings', component: Settings, name: "patient-settings", meta: { restrictedTo: 'patient' } },
    ] },
]
