const lang = {}
const requireComponent = require.context(
    './', false, /[\w-]+-en\.json$/
)

requireComponent.keys().forEach(fileName => {
    const translations = requireComponent(fileName)

    Object.assign(lang, translations)
})
export default lang
