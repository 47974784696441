import { defineRule, configure } from 'vee-validate';
import { required, email, is } from '@vee-validate/rules';
import { localize } from '@vee-validate/i18n';
import { PATTERN_VALIDATION } from '../constants';

defineRule('required', required);
defineRule('is', is);
defineRule('email', (value) => {
  const regex = new RegExp(PATTERN_VALIDATION.email, 'g')
  return regex.test(value)
});

defineRule('name', (value) => {
  const regex = new RegExp(PATTERN_VALIDATION.name)
  return regex.test(value)
});

defineRule('username', (value) => {
const regex = new RegExp(PATTERN_VALIDATION.username)
return regex.test(value)
});

defineRule('password', (value) => {
  const regex = new RegExp(PATTERN_VALIDATION.password)
  return regex.test(value)
});

defineRule('url', (value) => {
const regex = new RegExp(PATTERN_VALIDATION.url)
return regex.test(value)
});

configure({
  generateMessage: localize({
    'en': {
      messages: {
        required: 'This field is required.',
        email: 'The email is not valid.',
        username: 'The username is not valid.',
        name: 'The field is not valid.',
        password: "The password must contains : an uppercase, a lowercase, a digit and a special character.",
        is: "Passwords are not matching.",
        url: 'The url is not valid.'
      },
    },
    'fr': {
      messages: {
        required: 'Ce champ est requis.',
        email: "L'email n'est pas valide.",
        username: "Le nom d'utilisateur n'est pas valide.",
        name: "Le champ n'est pas valide.",
        password: "Le mot de passe doit contenir au moins : une majuscule, une miniscule, un chiffre et un caractère spécial.",
        is: "Les mots de passes ne sont pas les mêmes.",
        url: "L'url n'est pas valide."
      },
    },
  }),
});