const BaseAuth = () => import(/* webpackChunkName: "auth" */ './BaseAuth')
const Login = () => import(/* webpackChunkName: "auth" */ './LoginPage')
const LoginWithToken = () => import(/* webpackChunkName: "auth" */ './LoginWithToken')
const Logout = () => import(/* webpackChunkName: "auth" */ './LogoutPage')
const ForgetPassword = () => import(/* webpackChunkName: "auth" */ './ForgetPasswordPage')
const ResetPassword = () => import(/* webpackChunkName: "auth" */ './ResetPasswordPage')
const Register = () => import(/* webpackChunkName: "auth" */ './RegisterPage')
export default [
    { path: '/auth', component: BaseAuth, children: [
        { path: 'login', component: Login, name: "login", meta: {restrictedTo: "$unauthenticated"} },
        { path: 'loginWithToken', component: LoginWithToken, name: "loginWithToken" },
        { path: 'forget-password', component: ForgetPassword, name: "forget-password" },
        { path: 'reset-password', component: ResetPassword, name: "reset-password" },
        { path: 'logout', component: Logout, name: "logout", meta: { restrictedTo: '$authenticated' } },
        { path: 'register', component: Register, name: "register", meta: {restrictedTo: "$unauthenticated"}},
    ]}
]
