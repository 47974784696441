<template>
  <div id="app">
    <router-view />
    <notifications position="bottom right" />
  </div>
</template>

<script>
export default {
}
</script>

<style lang="scss">
@import '~@/assets/scss/app';
</style>
