// Discover all routes in sections
import { createWebHistory, createRouter } from "vue-router"
import { authenticationRoleGuard } from '@/services/guards-service'
import NotFoundPage from '@/components/NotFoundPage'

const routes = []

const requireComponent = require.context(
    '@/sections', true, /_routes\.js$/
)

requireComponent.keys().forEach(fileName => {
    const route = requireComponent(fileName).default
    routes.push(...route)
})

// Add 404 page
routes.push(
    { path :"/:pathMatch(.*)", component: NotFoundPage, name: "not-found-page"},
)

const router = createRouter({
    history: createWebHistory(),
    routes,
    scrollBehavior (to, from, savedPosition) {
        return new Promise((resolve) => {
            setTimeout(() => {
                if (savedPosition) {
                    return resolve(savedPosition)
                }

                return resolve({ x: 0, y: 0 })
            }, 200)
        })
    }
})



// configure guard
router.beforeEach((to, from, next) => {
    if (!to.matched.length) {
        next({ name: 'not-found-page'});
    }
    authenticationRoleGuard(to, from, next);

})

export default router
