<template>
    <transition name="modal-animation">
        <div  v-show="modalActive" class="modal" @click.self="close">
            <transition name="modal-animation-inner">
                <div v-show="modalActive" class="modal-inner" >
                    <div class="modal-header">
                        <slot name="confirm-title">
                            {{ $t('_global.labels.delete_title') }}
                        </slot>
                        <button type="button" @click="close" class="btn-close" click="close"  aria-label="close">
                        </button>
                    </div>
                    
                    <div name="modal-body">
                        <slot name="modal-body">
                        </slot>
                    </div>    
                    
                    <div class="modal-footer">
                        <button type="button" id="close" class="btn btn-secondary" @click="close">{{ cancelTitle || $t('_global.actions.cancel') }}</button>
                        <button type="button" id="confirm" class="btn btn-danger" @click="confirmDelete">{{ okTitle || $t('_global.actions.delete') }}</button>
                    </div>
                    
                </div>
            </transition>
        </div>
    </transition>
</template>

<script>
export default {
    name:"app-modal",
    props: ['modalActive', 'id', 'ok', 'okTitle', 'cancelTitle'],
    setup(props, { emit }) {
        const close = () => {
        emit("close");
        };
        return { close };
    },
    methods: {
      confirmDelete() {
        this.close()
        this.ok()
        
      }
    }
}
</script>

<style lang="scss" scoped>


.modal-animation-enter-active,
.modal-animation-leave-active {
  transition: opacity 0.3s cubic-bezier(0.52, 0.02, 0.19, 1.02);
}
.modal-animation-enter-from,
.modal-animation-leave-to {
  opacity: 0;
}
.modal-animation-inner-enter-active {
  transition: all 0.3s cubic-bezier(0.52, 0.02, 0.19, 1.02) 0.15s;
}
.modal-animation-inner-leave-active {
  transition: all 0.3s cubic-bezier(0.52, 0.02, 0.19, 1.02);
}
.modal-animation-inner-enter-from {
  opacity: 0;
  transform: scale(0.8);
}
.modal-animation-inner-leave-to {
  transform: scale(0.8);
}
.modal {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  width: 100vw;
  position: fixed;
  top: 0;
  left: 0;
  background-color: rgba(87, 87, 87, 0.7);
  .modal-inner {
    position: relative;
    max-width: 640px;
    width: 80%;
    box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06);
    background-color: rgb(255, 255, 255);
    border-radius: 2%;
    border: 1px solid rgba(51, 51, 51, .3);


    .modal-header {
      font-weight: bold;
      font-size: 20px;
      h1 {
          color: black;
      }
    }
    .modal-content {
        display: flex;
        flex-direction: column;
        h1 {
        font-size: 32px;
        color: black;
        }
        p {
        font-size: 18px;
        }
    }
  }
}
</style>