import {
    apiLogin,
    apiLoginToken,
    forgetPassword as apiForgetPassword,
    resetPassword as apiResetPassword,
} from '@/api/auth-api'
import usersApi from '@/api/users-api'
import { storeAccessToken, deleteAccessToken, isAdmin } from '@/services/auth-service'
import { ACCESS_TOKEN_TTL } from '@/constants'
import { notify } from "@kyvg/vue3-notification";

const authenticateUser = async (accessToken, saveInCookie = false) => {
    // set to header ...
    storeAccessToken(accessToken, saveInCookie)

    const response = await usersApi.me()
    const user = response.data

    return user
}

export default {
    namespaced: true,
    state: {
        accessToken: {},
        user: null,
        isAdmin: false,
    },

    mutations: {
        login(state, accessToken) {
            state.accessToken = accessToken
        },

        logout(state) {
            // reset state
            state.accessToken = {}
            state.user = null
        },

        set_user(state, user) {
            state.user = user
            state.isAdmin = isAdmin(user)
        },

        put_role(state, { id, role }) {

            // find the role and update it
            const index = state.user.roles.findIndex((elm) => elm.id === id)

            if (index >= 0) {
                state.user.roles[index] = role
            } else if (state.isAdmin) {
                // admin has all roles
                state.user.roles = [...state.user.roles, role]
            }
        },
        remove_role(state, { id }) {
            // find the role and remove it
            const index = state.user.roles.findIndex((item) => item.id === id)

            if (index >= 0) {
                state.user.roles.splice(index, 1)
            }
        },
    },

    getters: {
        token: state => state.accessToken.id,
        isAuthenticated: state => state.accessToken !== undefined && state.user !== null,
        user: state => state.user,
        roles: state => state.user ? state.user.roles : [],
    },

    actions: {
        async login({ commit }, { username, password, remember }) {
            const loginResponse = await apiLogin({
                username,
                password,
            })

            const accessToken = {
                createdAt: new Date(),
                ttl: ACCESS_TOKEN_TTL,
                ...loginResponse.data
            }
            const user = await authenticateUser(accessToken.token, remember)

            commit('login', accessToken)
            commit('set_user', user)

            return accessToken
        },

        async loginWithToken({commit}, id_token) {
            const loginResponse = await apiLoginToken({id_token})
            return loginResponse.data.token;
        },

        async accessTokenLogin({ commit }, { accessToken }) {
            try {
                const user = await authenticateUser(accessToken)

                commit('login', accessToken)
                commit('set_user', user)
            } catch (ex) {
                // token is not more accepted ?
                if (!ex.response || (ex.response.status === 401))
                {
                    deleteAccessToken()
                    notify({type: "warn", text: 'Votre session a expirée'})
                    commit('logout')
                }
            }

            return accessToken
        },

        async logout({ commit }) {

            /** 
            try {
                await apiLogout()
            } catch(ex) {
                this.$log.error(ex)
            } */

            // delete access token after api call, because the endpoint require it
            deleteAccessToken()
            commit('logout')
        },

        updateMyStateData({ commit, state }, user) {
            if (state.user.id === user.id
                || state.user.id === undefined) {
                commit('set_user', Object.assign({}, state.user, user))
            }
        },

        async forgetPassword(store, data) {
            await apiForgetPassword(data.email)

            return true
        },

        async resetPassword(store, data) {
            await apiResetPassword(data)

            return true
        },
    },
}
