import { createBackendApiCalls } from './base-api'

const api = createBackendApiCalls({
    endpoint: 'roles',
    methods: [
        'fetchAll',
        'getById',
        'countAll',
        'remove',
        'create',
        'update',
    ],
})

export default api
